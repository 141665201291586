<template>
  <div>
    <div class="card border-0 bg-light shadow-none mb-4">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col my-auto">
            <h6 class="fw-bold mb-0">Room Booking ID #{{ rb.id }}</h6>
          </div>
          <div class="col-auto my-auto ms-auto"></div>
        </div>

        <div v-if="!formToFillIn || formToFillIn.status != 'response_received'">
          <div class="row">
            <div class="col">
              <div class="alert alert-success">
                <h5 class="fw-bold">Health Questionnaire</h5>
                <p class="mb-0">
                  You are required to complete a health questionnaire before
                  arriving in retreat, you may begin your health questionnaire
                  now and return to complete it at a later stage if needed.
                </p>
                <div class="row">
                  <div class="col-auto ms-auto">
                    <button
                      class="btn btn-sm btn-success float-end"
                      @click="checkForm"
                    >
                      {{
                        formToFillIn
                          ? formToFillIn.status == "in_progress"
                            ? "Continue Health Questionnaire"
                            : "Complete Health Questionnaire"
                          : "Complete Health Questionnaire"
                      }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="!viewingPayments">
          <div class="row mb-3">
            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Arrival</h6>
                  <h5 class="text-success fw-bold">
                    {{ rb.arrival_date | formatDate }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Departure</h6>
                  <h5 class="text-success fw-bold">
                    {{ rb.departure_date | formatDate }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Payment Status</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.payment_status | formatStatus }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Room Type</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.room.type }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Room Config</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    {{ rb.room_config }}
                  </h5>
                </div>
              </div>
            </div>

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Cost</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ rb.total_cost.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Paid</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ rb.total_paid.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <!--  -->

            <div class="col-md-4 text-center mb-3">
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <h6 class="fw-bold">Total Outstanding</h6>
                  <h5 class="text-success fw-bold text-capitalize">
                    £{{ rb.total_outstanding.toFixed(2) }}
                  </h5>
                </div>
              </div>
            </div>

            <div
              class="col-md-4 text-center mb-3"
              v-if="rb.total_outstanding > 0"
            >
              <div class="card border-0 shadow-sm">
                <div class="card-body">
                  <router-link
                    :to="'/room-bookings/' + rb.id + '/pay'"
                    class="btn btn-lg btn-success"
                  >
                    <i class="far fa-credit-card me-2"></i>Make Payment
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--  -->

          <!-- Client Info -->

          <div class="row">
            <div class="col">
              <div class="card border-0">
                <div class="card-body">
                  <h5 class="text-success fw-bold mb-0">For your stay...</h5>

                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">
                          Booking Confirmation Sent
                        </div>
                        <div class="col-auto text-center ms-auto my-auto">
                          <i class="far fa-check"></i>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">Retreat Info Sent</div>
                        <div class="col-auto text-center ms-auto my-auto">
                          <i class="far fa-check"></i>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">
                          Health Questionnaire
                        </div>
                        <div class="col-auto ms-auto my-auto">
                          <button
                            class="btn btn-sm btn-success me-3"
                            v-if="
                              formToFillIn &&
                              formToFillIn.status == 'incomplete' &&
                              formToFillIn.status == 'in_progress'
                            "
                            @click="checkForm"
                          >
                            <i class="far fa-doc-alt me-2"></i>Fill in HQ
                          </button>
                        </div>
                        <div class="col-auto text-center my-auto">
                          <i
                            class="far"
                            :class="
                              formToFillIn
                                ? formToFillIn.status == 'incomplete'
                                  ? 'fa-times'
                                  : 'fa-check'
                                : 'fa-times'
                            "
                          ></i>
                        </div>
                      </div>
                    </li>

                    <li class="list-group-item">
                      <div class="row">
                        <div class="col fw-bold my-auto">Treatments Booked</div>

                        <div class="col-auto ms-auto my-auto">
                          <router-link
                            class="btn btn-sm btn-success me-3"
                            to="/treatments"
                          >
                            <i class="far fa-file-alt me-2"></i>Book Treatments
                          </router-link>
                        </div>
                        <div class="col-auto text-center my-auto">
                          <i class="far fa-times"></i>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["b", "rb"],
  data() {
    return {
      viewingPayments: false,
    };
  },
  computed: {
    formToFillIn() {
      return this.b.forms.find((x) => x.client_id == this.$store.user.id);
    },
  },
  methods: {
    checkForm() {
      if (this.formToFillIn) {
        this.$router.push("/forms/" + this.formToFillIn.id);
      } else {
        this.$axios
          .post(process.env.VUE_APP_API_URL + "/c/forms", {
            retreat_booking_id: this.b.id,
          })
          .then(({ data }) => {
            this.$router.push("/forms/" + data.id);
          });
      }
    },
    closePayments() {
      this.viewingPayments = false;
    },
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      return status.replace("_", " ");
    },
  },
  components: {},
};
</script>

<style></style>
