<template>
  <div>
    <div class="container" v-if="retreatBooking">
      <div class="row">
        <div class="col">
          <h5 class="fw-bold">
            Retreat Booking #{{ retreatBooking.id }} has
            {{ retreatBooking.room_bookings.length }} room{{
              retreatBooking.room_bookings.length === 0 ? "" : ""
            }}
            booked for {{ retreatBooking.total_nights }} nights in
            {{
              retreatBooking.retreat == "uk"
                ? "Amchara Somerset"
                : "Amchara Gozo"
            }}
          </h5>
        </div>
        <div class="col-auto ms-auto">
          <router-link class="btn btn-sm btn-outline-success" to="/bookings">
            <i class="far fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="mb-4">
        <small class="d-block mb-2"
          >Booking has {{ retreatBooking.clients.length }} clients</small
        >
        <div class="row">
          <div class="col">
            <button
              class="btn btn-sm btn-outline-success me-2"
              v-for="c in retreatBooking.clients"
              :key="c.id"
            >
              {{ c.name }}
              {{ c.id == retreatBooking.lead_client_id ? "(lead)" : "" }}
            </button>
          </div>
        </div>
      </div>

      <room-booking-details
        v-for="rb in retreatBooking.room_bookings"
        :key="rb.id"
        :b="retreatBooking"
        :rb="rb"
      ></room-booking-details>
    </div>
    <loading v-else></loading>
  </div>
</template>

<script>
import RoomBookingDetails from "./view-partials/RoomBookingDetails";
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      retreatBooking: null,
    };
  },
  methods: {
    fetchRetreatBooking() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL + "/c/bookings/" + this.$route.params.id
        )
        .then(({ data }) => {
          this.retreatBooking = data;
        });
    },
  },
  mounted() {
    this.fetchRetreatBooking();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
    formatStatus(status) {
      return status.replace("_", " ");
    },
  },
  components: {
    RoomBookingDetails,
    Loading,
  },
};
</script>

<style>
</style>